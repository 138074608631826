// Add Timeout to slideup Flash Messages
export default function () {
  //schickt flash callout success nach oben
  if ($('div.flash.callout').length) {
    $('div.flash.callout').parent('div').removeAttr('style');
    $('body').prepend($('body').find('div.flash.callout'));
    $('div.flash.callout').show();
    setTimeout(function () {
      $('div.flash.callout.success').slideUp('slow');
    }, 2000);
  }

  $('.close-subscribe-notice').on('click', function (ev) {
    ev.preventDefault();
    $(this).closest('.subscribe-parent').hide();
  });
}
