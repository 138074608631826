import DomElementHelpers from "../../../../../vendor/gems/data-cycle-core/app/assets/javascripts/helpers/dom_element_helpers";

class Voucher {
	constructor(form) {
		this.form = form;
		this.transactionEndpoint = this.form.dataset.transactionEndpoint;
		this.checkPath = "/en/Transaction/CheckVoucher";
		this.applyPath = "/en/Transaction/ApplyVoucher";
		this.dbCode = this.form.dataset.dbCode;
		this.settlerCode = this.form.dataset.settlerCode;
		this.transactionId = this.form.dataset.transactionId;
		this.currency = this.form.dataset.currency;
		this.amountToPay = DomElementHelpers.parseDataAttribute(
			this.form.dataset.amountToPay,
		);
		this.inputField = document.getElementById("voucher_code");
		this.checkButton = form.querySelector(".check-button");
		this.applyButton = form.querySelector(".apply-button");
		this.errorsContainer = form.querySelector(".errors-container");
		this.applyContainer = form.querySelector(".voucher-apply-container");
		this.checkedVoucherCode = null;
		this.errorCode = null;
		this.voucherAmount = null;
		this.voucherValidTo = null;
		this.amountToBePaid = null;
		this.setup();
	}
	setup() {
		this.inputField.addEventListener("change", this.resetData.bind(this));
		this.form.addEventListener("submit", this.submitForm.bind(this));
		this.applyButton.addEventListener("click", this.applyVoucher.bind(this));
	}
	applyVoucher(event) {
		event.preventDefault();

		DataCycle.disableElement(this.applyButton);

		const params = {
			dbCode: this.dbCode,
			masterId: this.transactionId,
			voucherCode: this.checkedVoucherCode,
		};

		$.ajax({
			url: this.transactionEndpoint + this.applyPath,
			method: "POST",
			data: params,
		})
			.done((data) => {
				if (data && data.success) {
					location.reload();
				} else {
					this.errorCode = data && data.errorCode;
					this.showErrorAndReset();
				}
			})
			.fail((_error) => {
				this.showErrorAndReset();
			});
	}
	submitForm(event) {
		event.preventDefault();

		DataCycle.disableElement(this.checkButton);
		this.errorsContainer.style.display = "none";
		this.inputField.value = this.inputField.value.trim();

		const params = {
			dbCode: this.dbCode,
			settlerCode: this.settlerCode,
			voucherCode: this.inputField.value.trim().toUpperCase(),
		};

		$.ajax({
			url: this.transactionEndpoint + this.checkPath,
			method: "POST",
			data: params,
		})
			.done((data) => {
				if (data && data.success) {
					this.checkedVoucherCode = params.voucherCode;
					this.voucherAmount = data.amountUsable;
					this.amountToBePaid =
						this.amountToPay >= this.voucherAmount
							? this.voucherAmount
							: this.amountToPay;
					this.voucherValidTo = new Date(data.validTo);
					this.showApplyContainer();
				} else {
					this.errorCode = data && data.errorCode;
					this.showErrorAndReset();
				}
			})
			.fail((_error) => {
				this.showErrorAndReset();
			});
	}
	showErrorAndReset() {
		const errorCode = this.errorCode || 0;

		I18n.translate(`voucher.check_error_codes.${errorCode}`).then((text) => {
			this.errorsContainer.innerText = text;
			this.errorsContainer.style.display = "block";
		});

		this.resetData();

		DataCycle.enableElement(this.checkButton);
	}
	resetData() {
		this.errorCode = null;
		this.checkedVoucherCode = null;
		this.voucherAmount = null;
		this.voucherValidTo = null;
		this.amountToBePaid = null;

		this.applyContainer.classList.add("hidden");
		DataCycle.enableElement(this.checkButton);
		DataCycle.enableElement(this.applyButton);
	}
	showApplyContainer() {
		DataCycle.enableElement(this.applyButton);

		this.applyContainer.querySelector(
			".voucher-amount-available > .value",
		).innerText = `${this.voucherAmount.toFixed(2)} ${this.currency}`;
		this.applyContainer.querySelector(
			".voucher-amount-to-be-paid > .value",
		).innerText = `${this.amountToBePaid.toFixed(2)} ${this.currency}`;
		this.applyContainer.querySelector(
			".voucher-remaining-amount > .value",
		).innerText = `${Math.max(
			0,
			this.voucherAmount - this.amountToBePaid,
		).toFixed(2)} ${this.currency}`;
		this.applyContainer.querySelector(".voucher-valid-to > .value").innerText =
			this.voucherValidTo.toLocaleString([], {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			});

		this.applyContainer.classList.remove("hidden");
	}
}

export default Voucher;
