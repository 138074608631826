export default function () {
  init();

  function init() {
    $(document).on('click', '.remove-embedded', event => {
      event.preventDefault();
      event.stopImmediatePropagation();

      $(event.currentTarget).closest('.embedded-content').remove();
    });
  }
}
