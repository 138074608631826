export default function () {
  $('.cancel-button').on('click', clickCancelCallback);

  function clickCancelCallback(event) {
    const element = document.getElementById('cancelReason');

    if (!element.checkValidity()) {
      event.preventDefault();
      event.stopImmediatePropagation();

      element.parentElement.classList.add('mdc-text-field--invalid');

      if (element.reportValidity) element.reportValidity();
      else element.focus();
    }
  }
}
