// New Payment
import Payment from './../components/payment';

export default function () {
  init();

  function init(container = document) {
    var paymentMethods = $(container).find('#available-payment-methods');
    $(container)
      .find('#payment-dialog')
      .find('form')
      .each((_index, element) => {
        new Payment(element, paymentMethods);
      });
  }
}
