const initializers = import.meta.globEager('./modules/initializers/*.js');

import DataCycleSingleton from '../../../vendor/gems/data-cycle-core/app/assets/javascripts/components/data_cycle';
import I18n from '../../../vendor/gems/data-cycle-core/app/assets/javascripts/components/i18n';

Object.assign(window, { I18n });

export default function () {
  DataCycle = window.DataCycle = new DataCycleSingleton({ EnginePath: '/admin' });

  $(function () {
    for (const path in initializers) {
      try {
        initializers[path].default();
      } catch (err) {
        console.error(err);
      }
    }
  });
}
