export default function () {
  let windowObjectReference = null;
  let previousUrl = null;

  $('.login-new-window').click(e => {
    e.preventDefault();
    e.stopImmediatePropagation();

    openSignInWindow($(e.currentTarget).prop('href'), $(e.currentTarget).text().trim());
  });

  function createForm(url, name) {
    let form = document.createElement('FORM');
    form.method = 'POST';
    form.action = url;
    form.target = name;

    let input = document.createElement('INPUT');
    input.name = $('meta[name="csrf-param"]').prop('content');
    input.type = 'hidden';
    input.value = $('meta[name="csrf-token"]').prop('content');
    form.appendChild(input);

    document.body.appendChild(form);
    return form;
  }

  function openSignInWindow(url, name) {
    // remove any existing event listeners
    window.removeEventListener('message', receiveMessage);

    // add the listener for receiving a message from the popup
    window.addEventListener('message', event => receiveMessage(event), false);

    // window features
    const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700';

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
        or if such pointer exists but the window was closed */
      let form = createForm(url, name);
      windowObjectReference = window.open('', name, strWindowFeatures);

      form.submit();
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
        then we load it in the already opened secondary window and then
        we bring such window back on top/in front of its parent window. */
      let form = createForm(url, name);
      windowObjectReference = window.open('', name, strWindowFeatures);

      form.submit();

      windowObjectReference.focus();
    } else {
      /* else the window reference must exist and the window
        is not closed; therefore, we can bring it back on top of any other
        window with the focus() method. There would be no need to re-create
        the window or to reload the referenced resource. */
      windowObjectReference.focus();
    }

    // assign the previous URL
    previousUrl = url;
  }

  function receiveMessage(event) {
    if (event.data) {
      let data = JSON.parse(event.data);
      if (data.redirectUrl) {
        window.location.replace(data.redirectUrl);
      }
    }
  }
}
