import QuillHelpers from '../../../../../vendor/gems/data-cycle-core/app/assets/javascripts/helpers/quill_helpers';

Array.prototype.equal_to = function (a) {
  return (
    this.length === a.length &&
    this.filter((e, i) => a[i].name === e.name && a[i].value === e.value).length === this.length
  );
};

export default function () {
  init();

  function init() {
    $(document).on('change', '.translation-selector', event => {
      event.preventDefault();
      event.stopImmediatePropagation();

      let selectedLocale = $(event.currentTarget).val();
      window.location.href = location.pathname + '?locale=' + selectedLocale;
    });

    if ($('.administration-form').length) pageLeaveWarning($('.administration-form'));
  }

  function pageLeaveWarning(form) {
    QuillHelpers.updateEditors(form);
    let initialFormData = form.serializeArray();
    let submitFormData = null;

    $(window).on('beforeunload', event => {
      QuillHelpers.updateEditors(form);
      submitFormData = form.serializeArray();
      if (initialFormData.length !== 0 && !initialFormData.equal_to(submitFormData))
        return 'Wollen Sie die Seite wirklich verlassen ohne zu speichern?';
    });
  }
}
