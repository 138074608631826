export default function () {
  init();

  $(document).on('gp:mdc:autoInit', event => {
    event.preventDefault();
    event.stopImmediatePropagation();

    window.mdc.autoInit();
  });

  function init() {
    window.mdc_dialogs = [].map.call(document.querySelectorAll('.mdc-dialog'), function (el) {
      return new window.mdc.dialog.MDCDialog(el);
    });

    var contentEls = document.querySelectorAll('.tab-content');
    var tabBars = [].map.call(document.querySelectorAll('.mdc-tab-bar'), function (el) {
      let tabBar = new window.mdc.tabBar.MDCTabBar(el);

      tabBar.listen('MDCTabBar:activated', function (event) {
        // Hide currently-active content
        document.querySelector('.tab-content--active').classList.remove('tab-content--active');
        // Show content for newly-activated tab
        contentEls[event.detail.index].classList.add('tab-content--active');
      });

      return tabBar;
    });

    $('[data-mdc-dialog-id]').on('click', event => {
      event.preventDefault();

      let dialog = window.mdc_dialogs.find(elem => elem.root_.id === event.currentTarget.dataset.mdcDialogId);

      if (dialog !== undefined) dialog.open();
    });
  }
}
