// Payment class
class Payment {
  constructor(form, paymentMethods) {
    this.paymentMethods = paymentMethods;
    this.form = $(form);
    this.formContainer = this.form.closest('.payment-method-container');
    this.feratelTransactionPath = '/en/Transaction/Payment';
    this.feratelPaymentStatusPath = '/en/Transaction/PaymentStatusExt';
    this.baseUrl = location.protocol + '//' + location.host + location.pathname;
    this.transactionEndpoint = this.form.data('transaction-endpoint');
    this.successUrl = this.baseUrl + '/payment';
    this.sequence = this.form.data('sequence');
    this.fetchStatus = this.form.data('fetch-status');
    this.amount = Math.round(parseFloat(this.form.data('amount')) * 100);
    this.dbCode = this.form.data('db-code');
    this.maserId = this.form.data('maser-id');
    this.paymentId = this.form.data('pay-id');
    this.language = this.form.data('language');

    this.initPaymentStatus();
    this.initEventHandlers();
    this.togglePaymentMethod(this.paymentMethods.find('input[name=payment-method]:checked').val());
  }
  initEventHandlers() {
    this.form.on('submit', this.doPayment.bind(this));
    this.paymentMethods.find('input[name=payment-method]').on('click', this.validatePaymentMethod.bind(this));
  }
  initPaymentStatus() {
    if (this.fetchStatus == true) {
      this.paymentCounter = 5;
      this.inpayment = true;

      this.showPaymentError = false;
      this.showPaymentSuccess = false;
      this.paymentError = '';

      this.payButton = this.formContainer.find('button[name=open_overlay_' + this.sequence + ']');
      this.spinner = this.formContainer.find('div.spinner');

      this.payButton.attr('disabled', 'disabled');
      this.spinner.show();

      setTimeout(this.fetchPayment.bind(this), 5000);
    }
  }
  showPaymentStatus() {
    this.paymentStatusOutput = this.formContainer.find('.payment-status');
    this.paymentStatusOutput.show();
    document.cookie = `current_payment=;SameSite=Lax;path=${window.location.pathname};expires=Thu, 01 Jan 1970 00:00:00 UTC;`;

    if (this.showPaymentError == true) {
      this.paymentStatusOutput.addClass('alert');
      this.paymentStatusOutput.html(this.paymentError);
      this.spinner.hide();
    } else if (this.showPaymentSuccess == true) {
      this.paymentStatusOutput.addClass('success');
      this.paymentStatusOutput.html('Zahlung wird bearbeitet');

      this.validatePaymentCounter = 30;
      setTimeout(this.validatePayment.bind(this), 10000);
    }
  }
  fetchPayment() {
    this.paymentCounter--;
    if (this.paymentCounter < 0) {
      this.showPaymentError = true;
      this.showPaymentSuccess = false;
      this.paymentError = 'TimeOut during Payment';
      this.inpayment = false;
      this.showPaymentStatus();
      return;
    }

    let params = {
      dbCode: this.dbCode,
      paymentSequence: this.sequence,
      paymentId: this.paymentId,
      transactionId: this.maserId
    };

    $.ajax({
      url: this.transactionEndpoint + this.feratelPaymentStatusPath,
      method: 'POST',
      data: params
    })
      .done(data => {
        if (data.success) {
          if (data.status < 0) {
            setTimeout(this.fetchPayment.bind(this), 5000);
          } else {
            if (data.status == 0) {
              this.showPaymentError = false;
              this.showPaymentSuccess = true;
            } else {
              this.showPaymentError = true;
              this.showPaymentSuccess = false;
              this.paymentError = data.error;
            }
            this.inpayment = false;
            this.showPaymentStatus();
          }
        } else {
          this.paymentError = data.error;
          if (data.error == undefined) {
            this.paymentError = 'An Error occured!';
          }
          this.showPaymentError = true;
          this.showPaymentSuccess = false;
          this.inpayment = false;
          this.showPaymentStatus();
        }
      })
      .fail(function (jqXHR, textStatus) {
        //error callback
        this.showPaymentError = true;
        this.showPaymentSuccess = false;
        this.paymentError = error;
        this.inpayment = false;
        this.showPaymentStatus();
      });
  }
  validatePayment() {
    this.validatePaymentCounter--;
    if (this.validatePaymentCounter < 0) {
      this.showPaymentError = true;
      this.paymentError = 'Leider ist ein Fehler aufgetreten';
      this.showPaymentStatus();
      return;
    }
    $.ajax({
      url: this.baseUrl + '/paymentStatus/' + this.sequence,
      method: 'GET'
    }).done(data => {
      if (data.status == true) {
        window.location.href = this.baseUrl;
      } else {
        setTimeout(this.validatePayment.bind(this), 10000);
      }
    });
  }
  doPayment(event) {
    if (event !== undefined) event.preventDefault();

    this.merchantId = this.form.data('merchant-id');
    this.merchantSign = this.form.data('merchant-sign');
    this.currency = this.form.data('currency');

    let params = {
      dbCode: this.dbCode,
      id: this.maserId,
      sequence: this.sequence,
      merchantId: this.merchantId,
      merchantSign: this.merchantSign,
      languageCode: this.language,
      successUrl: this.successUrl,
      paymentMethods: this.currentPaymentMethod()
    };

    $.ajax({
      url: this.transactionEndpoint + this.feratelTransactionPath,
      method: 'POST',
      data: params
    }).done(data => {
      let dataTransParams = {
        paymentmethod: this.currentPaymentMethod(),
        merchantId: this.merchantId,
        amount: this.amount,
        currency: this.currency,
        refno: data.refno,
        pid: data.pid,
        sign: this.merchantSign,
        successUrl: this.successUrl,
        errorUrl: this.successUrl,
        cancelUrl: this.successUrl,
        transactionId: this.maserId,
        sequence: this.sequence,
        websiteurl: this.successUrl
      };

      document.cookie = `current_payment=${JSON.stringify({
        sequence: this.sequence,
        pid: data.pid
      })};SameSite=Lax;path=${window.location.pathname}`;

      Datatrans.startPayment({
        transactionId: data.payTransactionId,
        params: dataTransParams
      });
    });

    return false;
  }
  currentPaymentMethod() {
    return this.paymentMethods.find('input[name=payment-method]:checked').val();
  }
  validatePaymentMethod(event) {
    this.togglePaymentMethod(event.target.value);
  }
  togglePaymentMethod(paymentMethodVal) {
    if (paymentMethodVal == 'invoice') {
      this.formContainer.find('button[name=open_overlay_' + this.sequence + '], .faq-link').hide();
      this.formContainer.find('#invoice-text').show();
    } else {
      this.formContainer.find('button[name=open_overlay_' + this.sequence + '], .faq-link').show();
      this.formContainer.find('#invoice-text').hide();
    }
  }
}

export default Payment;
