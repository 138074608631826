export default function () {
  init();

  $(document).on('gp:loadRatingStatus', event => {
    $(event.currentTarget)
      .find('.load-rating-status')
      .each((i, item) => loadRatingStatus(item));
  });

  function init(container = document) {
    $(container)
      .find('.load-rating-status')
      .each((i, item) => loadRatingStatus(item));

    $(container).on('click', '.submit-rating-button', event => {
      event.preventDefault();

      const $container = $(event.currentTarget).closest('.accordion-content');
      $container.find('.callout.validation-error').hide();
      const valid = isValid($container);
      const validSpecificQuestions = isValidSpecific($container);

      let dialog = window.mdc_dialogs.find(
        elem => elem.root_.id === event.currentTarget.dataset.mdcDialogWithValidations
      );
      if (valid && validSpecificQuestions && dialog) dialog.open();
    });

    $(container).on('click', '.next-rating-link', event => {
      event.preventDefault();

      const $container = $(event.currentTarget).closest('.accordion-content');
      $container.find('.callout.validation-error').hide();
      const valid = isValid($container);
      const validSpecificQuestions = isValidSpecific($container);

      if (valid && validSpecificQuestions) {
        const $accordion = $(event.currentTarget).closest('.accordion');
        const $nextAccordionContent = $(event.currentTarget)
          .closest('.accordion-item')
          .next('.accordion-item')
          .find('.accordion-content');

        if ($nextAccordionContent.length) {
          $accordion.foundation('down', $nextAccordionContent);
          window.scrollTo(0, 0);
        }
      }
    });

    $(container).on('click', '.prev-rating-link', event => {
      event.preventDefault();

      const $accordion = $(event.currentTarget).closest('.accordion');
      const $nextAccordionContent = $(event.currentTarget)
        .closest('.accordion-item')
        .prev('.accordion-item')
        .find('.accordion-content');

      if ($nextAccordionContent.length) {
        $accordion.foundation('down', $nextAccordionContent);
        window.scrollTo(0, 0);
      }
    });
  }

  function isValid(item) {
    const valid = !$(item)
      .find('> *:not(.no-validation)')
      .find(':input')
      .get()
      .some(v => !v.checkValidity());

    if (!valid) $(item).find('.callout.validation-error.general').show();

    return valid;
  }

  function isValidSpecific(item) {
    const valid = $(item).find('.question.specific :input').serializeArray().length > 0;

    if (!valid) $(item).find('.callout.validation-error.specific').show();

    return valid;
  }

  function loadRatingStatus(item) {
    $.ajax({
      url: $(item).data('url'),
      data: {
        identifier: $(item).prop('id')
      },
      dataType: 'script'
    }).fail(_error => {
      $(item).remove();
    });
  }
}
