export default function () {
	if ($("section.transaction-loading").length) init();

	function init() {
		let timeout = $("section.transaction-loading").data("timeout");
		if (Math.sign(timeout) !== 1) timeout = 5000;

		setTimeout(() => {
			loadStatus(1, 5000);
		}, timeout);
	}

	function loadStatus(newRetries, timeout = 10000) {
		const url = new URL(window.location);
		url.searchParams.set("retries", newRetries);

		$.getJSON(url)
			.done((data, _textStatus, xhr) => {
				if (xhr.status === 202)
					setTimeout(() => {
						loadStatus(data.retries);
					}, timeout);
				else {
					window.location.reload(true);
				}
			})
			.fail((xhr, _textStatus, _error) => {
				if (xhr.status === 404 && xhr.responseJSON) {
					$("body").addClass(xhr.responseJSON.body_class);
					$(".main-menu").remove();
					$("section.transaction-loading").replaceWith(xhr.responseJSON.html);
				}
			});
	}
}
