export default function () {
  init();

  function init() {
    $('.administration-form input[type="file"]').on('change', event => {
      event.preventDefault();
      event.stopImmediatePropagation();

      filePreview(event.currentTarget);
    });

    $('.administration-form .remove-file').on('click', event => {
      event.preventDefault();
      event.stopImmediatePropagation();

      $('.administration-form input[type="file"]').val(null).trigger('change');
    });
  }

  function filePreview(input) {
    $('.administration-form .hidden_logo_id_field').val(null);

    if (input.files && input.files[0]) {
      $('.administration-form .remove-file').addClass('visible');
      var reader = new FileReader();
      reader.onload = function (e) {
        $('.administration-form .selected-file').html('<img src="' + e.target.result + '"/>');
      };
      reader.readAsDataURL(input.files[0]);
    } else {
      $('.administration-form .selected-file img').remove();
      $('.administration-form .remove-file').removeClass('visible');
    }
  }
}
