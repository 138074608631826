import Rails from '@rails/ujs';
import jQuery from 'jquery';
import I18n from '../../../vendor/gems/data-cycle-core/app/assets/javascripts/components/i18n';
import DataCycleSingleton from '../../../vendor/gems/data-cycle-core/app/assets/javascripts/components/data_cycle';
DataCycle = window.DataCycle = new DataCycleSingleton({ EnginePath: '/admin' });
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js';
import '../../../vendor/gems/data-cycle-core/app/assets/javascripts/helpers/lazysizes_base64_uri';

Object.assign(window, { $: jQuery, jQuery, Rails, I18n });

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faFortAwesomeAlt } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faQuestionCircle,
  faClipboardListCheck,
  faAngleRight as faAngleRightLight,
  faGlobe
} from '@data-cycle/pro-light-svg-icons';
import {
  faTrash,
  faTimes,
  faCircleNotch,
  faPlus,
  faBan,
  faExternalLinkAlt,
  faSpinner,
  faEye,
  faEyeSlash,
  faUserCircle,
  faCheck as faCheckRegular
} from '@data-cycle/pro-regular-svg-icons';
import {
  faChevronLeft,
  faAngleRight,
  faCaretUp,
  faCaretDown,
  faBed,
  faRunning,
  faPlus as faPlusSolid,
  faReceipt,
  faInfoCircle,
  faCheck,
  faExclamationTriangle,
  faPlusCircle,
  faUserMinus,
  faUserSlash,
  faGift
} from '@data-cycle/pro-solid-svg-icons';

library.add(
  faExclamationTriangle,
  faFortAwesomeAlt,
  faEdit,
  faTrash,
  faTimes,
  faQuestionCircle,
  faClipboardListCheck,
  faAngleRightLight,
  faGlobe,
  faCircleNotch,
  faPlus,
  faBan,
  faExternalLinkAlt,
  faSpinner,
  faEye,
  faEyeSlash,
  faUserCircle,
  faCheckRegular,
  faChevronLeft,
  faAngleRight,
  faCaretUp,
  faCaretDown,
  faBed,
  faRunning,
  faPlusSolid,
  faReceipt,
  faInfoCircle,
  faCheck,
  faPlusCircle,
  faUserMinus,
  faUserSlash,
  faGift
);

try {
  Rails.start();
} catch {}

dom.watch();

import * as mdc from 'material-components-web';
// for accessing mdc as a window object
window.mdc = mdc;

import 'foundation-sites';
import 'motion-ui';

import App from '../scripts/app';
App();

document.addEventListener('DOMContentLoaded', function () {
  mdc.autoInit();
});

$(function () {
  $(document).foundation();
});
