export default function () {
  init();

  function init() {
    $('.password-visibility-toggle').click(event => {
      event.preventDefault();

      $(event.currentTarget).toggleClass('password-visible');

      let input = $(event.currentTarget).siblings('input');

      if (input.attr('type') == 'password') {
        input.attr('type', 'text');
      } else {
        input.attr('type', 'password');
      }
    });
  }
}
