import QuillHelpers from '../../../../../vendor/gems/data-cycle-core/app/assets/javascripts/helpers/quill_helpers';
import quillInit from '../../../../../vendor/gems/data-cycle-core/app/assets/javascripts/initializers/text_editor_init';

export default function () {
  quillInit();
  init();

  function init() {
    $('.administration-form').on('submit', event => {
      event.preventDefault();

      $(window).off('beforeunload');

      $(event.currentTarget)
        .find('.quill-editor')
        .each((i, node) => {
          QuillHelpers.updateEditors(node);
        });

      event.currentTarget.submit();
    });
  }
}
