export default function () {
  const validators = {
    luhnNumber: validateLuhnNumber
  };

  let luhnIcorrectText = '';

  init();

  function init() {
    if (!$('.key-card-form').length) return;

    I18n.translate('key_cards.validations.ski_data.luhn_number_incorrect').then(text => {
      luhnIcorrectText = text;
    });

    $(
      '.key-card-form .key-card-identifier input.chip-id-field, .key-card-form .key-card-identifier input.serial-number-field'
    ).on('change', event => {
      const parent = event.currentTarget.closest('.key-card-identifier');
      const luhnNumber = parent.querySelector('input.luhn-number-field');

      validateField(luhnNumber);
    });

    $('.key-card-form .key-card-identifier [data-validate]').on('change', event => {
      validateField(event.currentTarget);
    });

    $('.key-card-form').on('submit', event => {
      let valid = true;
      const form = event.currentTarget;

      $(form)
        .find('.key-card-identifier [data-validate]')
        .each((_i, item) => {
          validateField(item);

          if (!item.validity.valid) valid = false;
        });

      if (!valid) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }

  function validateField(target) {
    const validation = target.dataset.validate;

    target.setCustomValidity('');
    const valid = target.checkValidity();

    if (valid) {
      target.setCustomValidity(validators[validation](target));
      target.reportValidity();
    }
  }

  function validateLuhnNumber(item) {
    const parent = item.closest('.key-card-identifier');
    const chipId = parent.querySelector('input.chip-id-field').value;
    const serialNumber = parent.querySelector('input.serial-number-field').value;
    const luhnNumber = parent.querySelector('input.luhn-number-field').value;
    const value = `${chipId}${serialNumber}${luhnNumber}`.replaceAll(/[\s-]/g, '');
    const luhnDigit = parseInt(value.substring(value.length - 1, value.length), 10);
    const luhnLessKeyCard = value.substring(0, value.length - 1);

    if (calculateLuhn(luhnLessKeyCard) === luhnDigit) {
      return '';
    }

    return luhnIcorrectText;
  }

  function calculateLuhn(keyCard) {
    let sum = 0;
    for (let i = 0; i < keyCard.length; i++) {
      sum += parseInt(keyCard.substring(i, i + 1), 10);
    }
    const delta = new Array(0, 1, 2, 3, 4, -4, -3, -2, -1, 0);
    for (let i = keyCard.length - 1; i >= 0; i -= 2) {
      const deltaIndex = parseInt(keyCard.substring(i, i + 1), 10);
      const deltaValue = delta[deltaIndex];
      sum += deltaValue;
    }
    let mod10 = sum % 10;
    mod10 = 10 - mod10;
    if (mod10 === 10) {
      mod10 = 0;
    }
    return mod10;
  }
}
